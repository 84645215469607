import React, { Suspense, createContext, useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import 'src/scss/style.scss'
import { getRegisteredSchoolClass } from 'src/api/schoolRegisterService'
import CommonUtil from 'src/shared/CommonUtil'
import { MathJaxContext } from 'better-react-mathjax'
import Loader from 'src/components/Loader'
import { getDepartmentsAPI, getDesignationsAPI, getEmployeeTypesAPI, getEmployeesAPI, getLeaveTemplatesAPI, getSalaryTempsAPI } from 'src/api/employeeService'
import { getTag } from 'src/api/feeService'
import { getDaysAPI } from 'src/api/commonService'
import { getSessionsAPI } from 'src/api/sessionService'
import { Toaster } from 'react-hot-toast'
import data from 'src/data.json'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('src/pages/login/Login'))
const ForgotPassord = React.lazy(() => import('src/pages/forgotPassword/ForgotPassword'))
const Register = React.lazy(() => import('src/pages/register/Register'))
const RegisterSchool = React.lazy(() => import('src/pages/login/Login'))


export const appContext = createContext();
const App = () => {
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [employeeTypes, setEmployeeTypes] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [leaveStructures, setLeaveStructures] = useState([]);
  const [salaryStructures, setSalaryStructures] = useState([]);
  const [tags, setTags] = useState([])
  const [days, setDays] = useState([])
  const [classOptions, setClassOptions] = useState([]);
  const schoolId = CommonUtil.getSelectedSchool()
  const [sessionsData, setSessionsData] = useState([])
  const [leaveCategories, setLeaveCategories] = useState([])
  const [sectionOptions, setSectionOptions] = useState([])




  const getClassList = async () => {
    try {
      let resp = await getRegisteredSchoolClass(schoolId);
      if (resp && resp.code === 200) {
        delete resp.code;
        setClassOptions(resp);
        let clist = [{ label: "Select Class", value: "" }];
        let sList = {}
        for (var i = 0; i < resp.length; i++) {
          clist.push({ label: resp[i]['className'], value: resp[i]['id'] });
          sList[resp[i]['id']] = resp[i]['section']
        }
        CommonUtil.setJsonLocalStorage("selectedSchoolClass", clist);
        CommonUtil.setJsonLocalStorage("selectedSchoolClassSection", sList);
        setSectionOptions(sList)
      } else {
        console.log(resp.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const getDepartments = async () => {
    try {
      let resp = await getDepartmentsAPI();
      if (resp && resp.code === 200) {
        setDepartments(resp);
      } else {
        throw new Error(resp.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const getDesignations = async () => {
    try {
      let resp = await getDesignationsAPI();
      if (resp && resp.code === 200) {
        setDesignations(resp);
      } else {
        throw new Error(resp.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const getEmployeeTypes = async () => {
    try {
      let resp = await getEmployeeTypesAPI();
      if (resp && resp.code === 200) {
        setEmployeeTypes(resp);
      } else {
        throw new Error(resp.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const getEmployees = async (params) => {
    try {
      let resp = await getEmployeesAPI(params);
      if (resp && resp.code === 200) {
        setEmployeeData(resp);
      } else {
        throw new Error(resp.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const getEmployeesByParams = async (params, setFunc) => {
    try {
      let resp = await getEmployeesAPI(params);
      if (resp && resp.code === 200) {
        setFunc(resp);
      } else {
        throw new Error(resp.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const fetchTags = async () => {
    try {
      let resp = await getTag(schoolId)
      if (resp && resp.code === 200) {
        setTags(resp)
      } else {
        setTags([])
        throw new Error('error in fetching Tags ')
      }
    } catch (error) {
      console.log(error);
    }
  }
  const getDays = async () => {
    try {
      const resp = await getDaysAPI();
      if (resp && resp?.code === 200) {
        setDays(resp);
      } else {
        throw new Error(resp.message);
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getLeaveTemplates = async () => {
    try {
      let resp = await getLeaveTemplatesAPI()
      if (resp && resp?.code === 200) {
        setLeaveStructures(resp)
      } else {
        setLeaveStructures([])
        throw new Error(resp?.message)
      }
    } catch (error) {
      console.log(error.message)
    }
  }
  const getSalaryTemps = async () => {
    try {
      let resp = await getSalaryTempsAPI()
      if (resp && resp?.code === 200) {
        setSalaryStructures(resp)
      } else {
        setSalaryStructures([])
        throw new Error(resp?.message)
      }
    } catch (error) {
      console.log(error.message)
    }
  }
  const getSessions = async () => {
    try {
      let resp = await getSessionsAPI()
      if (resp?.code === 200) {
        setSessionsData(resp?.rows)
      } else {
        throw new Error(resp?.message)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getLeaveCategories = async () => {
    try {
      let resp = data?.leaveCategories
      setLeaveCategories(resp)
    } catch (error) {
      console.log(error)
    }
  }



  useEffect(() => {
    const isLoggedIn = CommonUtil.getLocalStorage("access_token")
    if (isLoggedIn) {
      getDepartments()
      getDesignations()
      getEmployeeTypes()
      getEmployees()
      fetchTags()
      getDays()
      getLeaveTemplates()
      getSalaryTemps()
      getClassList()
      getSessions()
      getLeaveCategories()
    } else {
      if (!['/login', "/"].includes(window.location.pathname)) {
        window.location.href = "/";
      }
    }
  }, [])



  return (
    <appContext.Provider value={{ fetchTags, getClassList, departments, getDepartments, designations, getDesignations, employeeTypes, getEmployeeTypes, employeeData, getEmployees, tags, classOptions, days, getDays, leaveStructures, getLeaveTemplates, getSalaryTemps, salaryStructures, getSessions, sessionsData, getLeaveCategories, leaveCategories, sectionOptions,getEmployeesByParams }}>
      <MathJaxContext>
        <BrowserRouter>
          <Suspense fallback={
            <div style={{
              height: "100vh",
            }} >
              <Loader />
            </div>
          }>
            <Routes>
              <Route exact path="/" name="Login Page" element={<Login />} />
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route exact path="/forgot-password" name="Login Page" element={<ForgotPassord />} />
              <Route exact path="/register" name="Register Page" element={<Register />} />
              <Route exact path="/register-school" name="Register Page" element={<RegisterSchool />} />
              <Route exact path="*" name="Home" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </MathJaxContext>
      <Toaster />
    </appContext.Provider>
  )
}

export default App
