import moment from "moment";
import CommonUtil from "../shared/CommonUtil";


export const baseurl = 'https://dev2.myleadingcampus.com/api';

// export const baseurl = 'https://mlc-app.onrender.com/api';
// export const baseurl = "https://prod1.myleadingcampus.com/api";
// export const baseurl = "http://localhost:8081/api";


let refreshTokenApiInProgress = false;

const HTTP_STATUS_CODES = {
    '200': 'OK',
    '201': 'Created',
    '202': 'Accepted',
    '203': 'Non-Authoritative Information',
    '204': 'No Content',
    '205': 'Reset Content',
    '206': 'Partial Content',
    '300': 'Multiple Choices',
    '301': 'Moved Permanently',
    '302': 'Found',
    '303': 'See Other',
    '304': 'Not Modified',
    '305': 'Use Proxy',
    '307': 'Temporary Redirect',
    '400': 'Bad Request',
    '401': 'Unauthorized',
    '402': 'Payment Required',
    '403': 'Forbidden',
    '404': 'Not Found',
    '405': 'Method Not Allowed',
    '406': 'Not Acceptable',
    '407': 'Proxy Authentication Required',
    '408': 'Request Timeout',
    '409': 'Conflict',
    '410': 'Gone',
    '411': 'Length Required',
    '412': 'Precondition Failed',
    '413': 'Request Entity Too Large',
    '414': 'Request-URI Too Long',
    '415': 'Unsupported Media Type',
    '416': 'Requested Range Not Satisfiable',
    '417': 'Expectation Failed',
    '500': 'Internal Server Error',
    '501': 'Not Implemented',
    '502': 'Bad Gateway',
    '503': 'Service Unavailable',
    '504': 'Gateway Timeout',
    '505': 'HTTP Version Not Supported'
};

// axios.interceptors.response.use(response => response, error => {
//     if (error.response.status === 401) alert("E");
//     return error;
// });

const errorHandlingForApiResponse = function (response) {
    return {
        status: response.status,
        title: HTTP_STATUS_CODES[response.status],
        data: response.data
    }
}

const onErrorResponse = function (error) {
    // console.log(error);
    // console.log(error.response)
    // console.log(error.response.status)
    if (error.response && error.response.status === 400) {
        return {
            type: 'error',
            title: 'Error',
            message: error.response.data.error,
            data: null
        }
    } else {
        return {
            type: 'error',
            title: 'Error',
            message: 'Request Failed',
            data: null
        }
    }
}

export const refreshRequest = async function (obj) {
    refreshTokenApiInProgress = true;
    // if(obj.method === 'delete' || obj.method === 'DELETE'){
    //     let confirmation = window.confirm("Are you sure you want to delete this record?");
    //     if(!confirmation){
    //         return;
    //     }
    // }
    return await fetch(baseurl + obj.url, {
        method: obj.method, // *GET, POST, PUT, DELETE, etc
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            "authorization": 'Bearer ' + CommonUtil.getLocalStorage('refresh_token')
            // 'Content-Type': 'application/x-www-form-urlencoded',
        }
    })
        .then(res => { if (res.status === 204) { return {} } else { return res.json() } })
        .then(
            (result) => {
                // console.log(result);
                if (!result.code) {
                    result.code = 200;
                }
                return result;
            },
            (error) => {
                console.log(error);
                error.type = "error";
                return error;

            }
        )
}

const save = function (blob, fileName) {
    if (window.navigator.msSaveOrOpenBlob) { // For IE:
        navigator.msSaveBlob(blob, fileName);
    } else { // For other browsers:
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(link.href);
    }
}


const apiService = async function (obj) {
    // console.log(CommonUtil.getLocalStorage('refresh_token_exp_time'))

    if (!CommonUtil.getLocalStorage('refresh_token_exp_time') ||
        CommonUtil.getLocalStorage('refresh_token_exp_time') === "null" ||
        moment(CommonUtil.getLocalStorage('refresh_token_exp_time')).diff(moment(), "seconds") <= 0) {
        if (window.location.pathname === '/' || window.location.pathname === '/login' || window.location.pathname === '/signup' ||
            window.location.pathname === '/forgot-password' || window.location.pathname === '/reset-password') {

        } else {
            window.location.pathname = '/login';
            localStorage.removeItem("businessName");
            localStorage.removeItem('access_token');
            localStorage.removeItem('access_token_exp_time');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('refresh_token_exp_time');
            localStorage.removeItem('selectedSchool');
            // alert("Session expired. Please login again(check 1)")
            return ({ message: 'Session expired. Please login again' });
        }

    }


    if (!refreshTokenApiInProgress && CommonUtil.getLocalStorage('access_token_exp_time') && moment(CommonUtil.getLocalStorage('access_token_exp_time')).diff(moment(), "seconds") <= 0) {
        let resp = await refreshRequest({
            url: "/auth/refresh-token",
            method: 'get'
        });
        if (resp && resp.code === 200) {
            CommonUtil.setLocalStorage('access_token', resp.access.token);
            CommonUtil.setLocalStorage('access_token_exp_time', resp.access.expires);
            CommonUtil.setLocalStorage('refresh_token', resp.refresh.token);
            CommonUtil.setLocalStorage('refresh_token_exp_time', resp.refresh.expires);
            refreshTokenApiInProgress = false;
            if (obj.url.indexOf('logout') === -1) {
                obj.Authorization = 'Bearer ' + resp.access.token;
            }
        } else {
            return ({ message: 'Session expired. Please login again' });
        }
    }
    let isdownload = false;
    let filenameForDownload = '';
    // let isFileDownloadError = false;
    if (obj.params && Object.keys(obj.params).length > 0) {
        if (obj.params.hasOwnProperty('download') || obj.params.hasOwnProperty('isDownload')) {
            isdownload = true;
            filenameForDownload = obj.params.fileName + '.xlsx';
            delete obj.params.fileName;
        }

        // obj.url = new
        let qs = Object.keys(obj.params).reduce(function (_qs, k, i) { return _qs + '&' + k + '=' + obj.params[k]; }, '').substring(1);
        obj.url = obj.url + "?" + qs;
        // obj.url = new URL(obj.url),
        //     Object.keys(obj.params).forEach(key => obj.url.searchParams.append(key, obj.params[key]))

    }


    let settings = {
        method: obj.method, // *GET, POST, PUT, DELETE, etc
        credentials: 'include',
        headers: {
            'Content-Type': obj.content_type ? obj.content_type : 'application/json',
            "module_id": obj.module_id ? obj.module_id : "",
            "passaccess": obj.passaccess ? obj.passaccess : false,
            "authorization": obj.Authorization ? obj.Authorization : 'Bearer ' + CommonUtil.getLocalStorage('access_token'),
        },
        body: JSON.stringify(obj.data),// body data type must match "Content-Type" header
        // credentials: 'same-origin'
    }

    if (obj.isFormData) {
        delete settings['headers']['Content-Type'];
        settings['body'] = obj.data;
    }

    return await fetch(baseurl + obj.url, settings)
        .then(res => {
            if (res.status === 204) {
                return {};
            } else {
                if (isdownload || obj.params?.hasOwnProperty("isFeesDueDownload") || obj.params?.hasOwnProperty("exportType")) {
                    if (res.status === 200 || res.status === 201) {
                        console.log("download", res)
                        return res.blob();
                    } else {
                        return res.json();
                    }
                }
                if (obj.content_type === 'text/html') {
                    return res.text();
                } else {
                    console.log("json", res)
                    return res.json();
                }
            }
        })
        .then(
            (result) => {
                console.log(result)
                if (result.code === 200 && isdownload) {
                    let href = window.URL.createObjectURL(result);
                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = href;
                    a.download = filenameForDownload;
                    a.click();
                    return { code: 200 };
                }

                if (result.code === 401) {
                    sessionStorage.removeItem("businessName");
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('access_token_exp_time');
                    localStorage.removeItem('refresh_token');
                    localStorage.removeItem('refresh_token_exp_time');
                    if (window.location.pathname !== '/' || moment(CommonUtil.getLocalStorage('access_token_exp_time')).diff(moment(), "seconds") <= 0) {
                        window.location.href = '/';
                    }
                }
                if (!result.code && obj.content_type !== 'text/html') {
                    result.code = 200;
                }
                return result;
            },
            (error) => {
                console.log(error);
                error.type = "error";
                // callback(error);
                return error;

            }
        )
}

export default apiService;
